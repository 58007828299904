export function animErrorForm() {
    let button = document.getElementById("add-trigger");
    let advice = document.getElementById("advice");
    let container = document.getElementsByClassName("page")[0];

    button.classList.add('shake-horizontal');
    container.style.backgroundColor = "rgba(255, 88, 95, 0.7)";
    advice.style.color = "rgba(255, 88, 95, 1)";
    setTimeout(()=>{
        button.classList.remove("shake-horizontal");
        container.style.backgroundColor = "#F0F0F0";
        advice.style.color = "rgb(120,120,120)";
    }, 1000)
}

export function animErrorPost() {
    let advice = document.getElementById("advice");
    let container = document.getElementsByClassName("page")[0];

    container.style.backgroundColor = "rgba(255, 88, 95, 0.7)";
    advice.style.color = "rgba(255, 88, 95, 1)";
    setTimeout(()=>{
        container.style.backgroundColor = "#F0F0F0";
        advice.style.color = "rgb(120,120,120)";
    }, 1000)
}