import React from "react"
import "./style.scss"


export default function Checkbox(props) {

    return(
        <div className={"checkbox"} onClick={props.onClick}>
            <input type={"checkbox"} checked={props.value}/>

            <label>{props.label}</label>
        </div>
    )
}