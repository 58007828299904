import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Button, Modal} from "react-bootstrap";
import { InputTime } from "../Form/Input/Index";
import "./style.scss";
import loader from "../../res/icons/loader-black.svg";
import Checkbox from "../Form/Checkbox";


export default class EditForecastModal extends React.Component {
    render() {
        let forecast = this.props.forecast;

        if(typeof forecast !== "undefined" && forecast !== null){
            return (
                <Modal size={"md"} centered show={this.props.show} onHide={this.props.handleClose}>
                    <Modal.Header>
                        <Modal.Title>{"Edit Forecast"}</Modal.Title>
                        <span>Hour per day: {forecast.content}</span>
                    </Modal.Header>

                    <Modal.Body className={"page"}>
                        <div className="edit-container">
                            <InputTime
                                label={"Time per Day"}
                                icon={faHourglassHalf}
                                value={this.props.timePerDay}
                                onChange={(time) => this.props.timePerDayChange(time)}
                            />

                            <Checkbox label={"Forecast prévisionnel"} value={this.props.previsionnel}
                                      onClick={()=> this.props.forecastChange(!this.props.previsionnel)}/>

                            <p id={"advice"}>{this.props.errorMessage}</p>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="footer">
                        {this.props.loading ? 
                            <img src={loader} alt={"loader"}/> :
                            <>
                                <Button onClick={this.props.modifyForecast} id={"add-trigger"}>
                                    Save
                                </Button>

                                <Button className={"important"} onClick={this.props.handleClose}>
                                    Cancel
                                </Button>
                            </> 
                        }
                    </Modal.Footer>
                </Modal>
            );
        }
        else return null;
    }
}