export function minutesToClock(minutesSpent, delimiter) {

    let hours = Math.floor(minutesSpent / 60);
    let minutes = minutesSpent - hours * 60;

    if(minutes.toString().length === 1) minutes = "0" + minutes;

    return hours + delimiter + minutes;
}


export function clockToMinutes(clock, delimiter) {

    let hoursMinutes = clock.split(delimiter);
    return hoursMinutes[0] * 60 + parseInt(hoursMinutes[1]);
}

export  function checkIsDay(startTime,endTime){
    const diffTime = Math.abs(endTime - startTime);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 2) {
        return true;
    } else {
        return false;
    }
}

export function getConsecutiveRanges(range) {
    let length = 1;
    const list = [];

    //If empty
    if (range.length == 0) {
        return list;
    }

    for(let i = 1; i <= range.length; i++) {
        // Check difference between current & previous elements
        if(i == range.length || range[i] - range[i - 1] != 1){
            if (length == 1) {
                list.push([range[i - length]]);
            }
            else {
                list.push([range[i - length],range[i - 1]]);
            }
            //initialize to find next range
            length = 1;
        }
        else {
            length ++;
        }
    }
    return list;
}