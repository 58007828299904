import {url} from "../conf/env";
import {getForecastRawData, updateForecastRawData} from "./provider";


export function initMercureConnection(){
    let hubMercure = new URL(url.mercure);
    hubMercure.searchParams.append('topic', url.topic + '/api/forecasts/{id}');
    hubMercure.searchParams.append('topic', url.topic + '/api/reports/{id}');

    let eventSource = new EventSource(hubMercure);
    eventSource.onmessage = e => treatMercureEvent(e);
}


function treatMercureEvent(event) {

    const data = JSON.parse(event.data);
    const object_name = data['@id'].split("/")[2];
    const rawData = getForecastRawData();
    
    if(rawData && rawData[object_name]) {
        const doublon = rawData[object_name].find((object) => object['@id'] === data['@id']);

        if (doublon !== undefined) {
            if (Object.keys(data).length <= 1) removeObjectFromData(rawData, object_name, doublon)
            else updateObjectFromData(rawData, object_name, doublon, data)
        } else addNewOjectToData(rawData, object_name, data)
    }
}


function addNewOjectToData(rawData, object_name, new_data){

    rawData[object_name].push(new_data);
    updateForecastRawData(rawData);
}


function updateObjectFromData(rawData, object_name, doublon, modified_data){

    const doublonIndex = rawData[object_name].indexOf(doublon);
    rawData[object_name][doublonIndex] = modified_data;
    updateForecastRawData(rawData);
}


function removeObjectFromData(rawData, object_name, doublon, deleted_data){

    const indexObject = rawData[object_name].indexOf(doublon)
    rawData[object_name].splice(indexObject, 1)
    updateForecastRawData(rawData)
}