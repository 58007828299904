import React from "react";
import {SidebarHeader, TimelineHeaders, CustomHeader} from "react-calendar-timeline";
import {generateDateLabel} from "../../helpers/date-helper";
import moment from "moment";


const CalendarHeaders = (props, onFilterChange, onHeaderClick) =>{

    const headerHeight = 85 / props.headers.length;


    return(
        <TimelineHeaders className={"sticky timeline-headers"}>

            <SidebarHeader>
                {() => {
                    return <input id={"search-in"} type={"text"} placeholder={"Refine your results with any term ..."} autoFocus={true}
                                  value={props.filter.now} onChange={onFilterChange}/>
                }}
            </SidebarHeader>

            {props.headers.map((headerUnit) =>

                <CustomHeader key={headerUnit + "-header"} height={headerHeight} unit={headerUnit}>

                    {({headerContext: { intervals }, getRootProps, getIntervalProps, showPeriod}) => {
                        return (
                            <div className={"interval-group"} {...getRootProps()}>
                                {intervals.map(interval => {

                                    let isTodayInterval = false;
                                    if(headerUnit === "day") isTodayInterval = moment().week() === interval.startTime.week() && moment().day() === interval.startTime.day();
                                    else if(headerUnit === "hour") isTodayInterval = moment().week() === interval.startTime.week() && moment().day() === interval.startTime.day() && moment().hour() === interval.startTime.hour();

                                    return (
                                        <div className={(isTodayInterval)? "interval active " + headerUnit + "-interval" : "interval " + headerUnit + "-interval"}
                                             onClick={() => {
                                                onHeaderClick(headerUnit);
                                                if(["month", "week", "day"].includes(headerUnit))
                                                    showPeriod(interval.startTime, interval.endTime);
                                            }}

                                            {...getIntervalProps({
                                                interval, style: {lineHeight: headerHeight + "px"}
                                            })}
                                        >
                                            <div>
                                                {generateDateLabel(headerUnit, interval.startTime, interval.labelWidth)}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}}
                </CustomHeader>
            )}
        </TimelineHeaders>
    );
}


export default CalendarHeaders