import React from "react";
import "./style.scss";
import {withRouter} from 'react-router-dom'


class ProjectGroup extends React.Component {

    redirect(redirection){
        this.props.history.push(redirection)
    }

    render(){

        let group = this.props.group;

        if(group.type === "line")
            return (
                <div className={"line-group"} style={{borderLeft: "5px solid " + group.color}}
                    onClick={group.onGroupClick}>

                    <div className={"about-group"}>
                        <div><p className="name">{group.name}</p><span className={"client"}>{group.client}</span></div>
                        <h3 className="title">{group.title}</h3>
                    </div>

                    {group.toggle}
                </div>
            );

        else
            return (
                <div onClick={() => this.redirect(group.redirectTo)} className={"underline-group"}
                     style={{borderLeft: "5px solid " + group.color, cursor: 'pointer'}}>
                    <div className={"about-group"}>
                        <h3 className="title">{group.title}</h3>
                        <div><p className="name">{group.tags}</p></div>
                    </div>
                </div>
            )
    }
}

export default withRouter(ProjectGroup);