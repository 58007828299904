import React from "react";
import "./style.scss";
import {withRouter} from 'react-router-dom'


class TeamGroup extends React.Component {

    redirect(redirection){
        this.props.history.push(redirection)
    }

    render() {

        let group = this.props.group;

        if(group.type === "line"){

            if(group.team_type === "TEAM_PROJECT")
                return (
                    <div className={"line-group"} style={{borderLeft: "5px solid " + group.color, fontStyle: 'normal'}}
                         onClick={group.onGroupClick}>

                        <div className={"about-group"}>
                            <div><p className="name">{group.name}</p><span className={"client"}>{group.client}</span></div>
                            <h3 className="title">{group.title}</h3>
                        </div>

                        {group.toggle}
                    </div>
                );

            else
                return (
                    <div className={"line-group"} style={{borderLeft: "5px solid " + group.color, fontStyle: 'italic', backgroundColor: "rgba(246, 189, 96, 0.15)"}}
                         onClick={group.onGroupClick}>

                        <div className={"about-group"}>
                            <div><p className="name">{group.name}</p><span className={"client"}>{group.client}</span></div>
                            <h3 className="title">{group.title}</h3>
                        </div>

                        {group.toggle}
                    </div>
                );
        }


        else
            return (
                <div onClick={() => this.redirect(group.redirectTo)} style={{cursor: "pointer", borderLeft: "5px solid " + group.color}} className={"underline-group"}>
                    <div className={"about-group"}>
                        <h3 className="title">{group.title}</h3>
                        <div><p className="name">{group.tags}</p></div>
                    </div>
                </div>
            );
    }
}

export default withRouter(TeamGroup);