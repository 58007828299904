import React from "react";
import {InputDate, InputTime, InputType} from "../../components/Form/Input/Index";
import {faFolderOpen, faHourglassEnd, faHourglassHalf, faHourglassStart, faUser, faFile} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../../components/Form/Dropdown/Index";
import {faCommentDots} from "@fortawesome/free-regular-svg-icons";
import {validateDATE, validateOBJECT, validateTIME} from "../../helpers/data-validation";
import {animErrorForm} from "../../helpers/animation";
import {getForecastRawData} from "../../providers/provider";
import EventBus from "eventbusjs";
import Checkbox from "../../components/Form/Checkbox";
import Cookies from "js-cookie";
import {Base64} from "js-base64"
import moment from "moment";
import {getConsecutiveRanges} from "../../helpers/format-helper";


export default class ForecastForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            timePerDay: "01:00",
            startDate: "",
            endDate: "",
            employee: null,
            project: null,
            devis: null,
            content: "",
            previsionnel: false,
            repeated: false,
            days: Array(5).fill(false),
            employeesList: null,
            projectsList: null,
            clientsList: null,
            teamsList: null,
            devisProjectList: null,
            devisData: null,
            loggedUserData: JSON.parse(Base64.decode(Cookies.get("sso-forecast-data")))
        };

        this.loadProjectDevis = this.loadProjectDevis.bind(this);
        this.getPageData = this.getPageData.bind(this);
    }


    componentDidMount(): void {
        this.getPageData();
        EventBus.addEventListener('raw-data-event', this.getPageData);
    }


    componentWillUnmount(): void {
        EventBus.removeEventListener('raw-data-event', this.getPageData);
    }


    getPageData = function(){
        const employees = getForecastRawData("employees");
        const projects = getForecastRawData("projects");
        const devis = getForecastRawData("devis");
        const clientsList = getForecastRawData("clients");
        const teams = getForecastRawData("teams");

        if(employees !== null && projects !== null && devis !== null && teams !== null){

            let employeesList = {};
            employeesList.metadata = employees.filter((employee) => employee.enabled).sort((a, b) => a.firstName.localeCompare(b.firstName))
            employeesList.displayData = [];
            employeesList.metadata.forEach((employee) => employeesList.displayData.push(employee.firstName + " " + employee.lastName));

            let projectsList = {};
            projectsList.metadata = projects.filter((project) => project.enabled).sort((a, b) => a.name.localeCompare(b.name))
            projectsList.displayData = [];
            projectsList.metadata.forEach((project) => projectsList.displayData.push(project.name));

            let teamsList = {};
            teamsList.metadata = teams.filter((team) => team).sort((a, b) => a.name.localeCompare(b.name))
            teamsList.displayData = [];
            teamsList.metadata.forEach((team) => teamsList.displayData.push(team.name));

            this.setState({employeesList, projectsList, devisData: devis, clientsList, teamsList});
        }
    };


    loadProjectDevis(project_metadata){
        let project_identifier = project_metadata['@id'];
        let devisProjectList = {};

        devisProjectList.metadata = this.state.devisData.filter((devis) => devis.project === project_identifier && devis.enabled).sort((a, b) => {
            if (a.tag > b.tag)
                return 1;
            if (b.tag > a.tag)
                return -1;
            return 0;
        });

        devisProjectList.displayData = [];
        devisProjectList.metadata.forEach((devis) => devisProjectList.displayData.push(devis.tag));
        this.setState({devisProjectList});
    }

    selectDays(index){
        this.state.days[index] = !this.state.days[index];
        this.setState(this.state);
    }

    addForecast(){
        const selectedDays = [];
        this.state.days.forEach((isSelected, index) => {
          if(isSelected) {
              selectedDays.push(index+1);
          }
        });

        const ranges = getConsecutiveRanges(selectedDays);
        const forecasts = [];

        if(validateTIME(this.state.timePerDay) && validateDATE(this.state.startDate) && validateDATE(this.state.endDate) &&
        validateOBJECT(this.state.employee, this.state.employeesList)
        && validateOBJECT(this.state.project, this.state.projectsList)
        && validateOBJECT(this.state.devis, this.state.devisProjectList)
        && this.state.startDate.getTime() <= this.state.endDate.getTime()) {
            const limit = new Date( this.state.startDate.getFullYear(),  this.state.startDate.getMonth()+3,  this.state.startDate.getDate());

            let newForecast = {
                timePerDay: this.state.timePerDay,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                employee: this.state.employee,
                project: this.state.project,
                devis: this.state.devis,
                content: this.state.content,
                previsionnel: this.state.previsionnel
            };

            if (this.state.repeated) {
                ranges.forEach(range => {
                    let date = this.state.startDate;

                    while (moment(date).format('d') != range[0]) {
                        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
                    }

                    while (moment(date).format('d') == range[0] && date <= this.state.endDate && date <= limit) {
                        if (range.length == 1) {
                            newForecast = {
                                ...newForecast,
                                startDate: date,
                                endDate: date,
                            };
                        } else {
                            const daysDifference = range[1] - range[0];
                            let endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysDifference);
                            newForecast = {
                                ...newForecast,
                                startDate: date,
                                endDate: endDate,
                            };
                        }
                        forecasts.push(newForecast)
                        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
                    }
                });
                this.props.addForecast(forecasts);
                this.resetForm();
                return;
            }

            this.props.addForecast(newForecast);
            this.resetForm();
        }
        else{
            console.error("form error");
            animErrorForm();
        }
    }


    resetForm(){
        this.setState({
            timePerDay: "01:00",
            startDate: "",
            endDate: "",
            employee: null,
            project: null,
            devis: null,
            content: "",
            previsionnel: false
        });
    }


    render() {
        return (
            <div className={"form content"} style={(this.props.display)? {display: "flex"} : {display: "none"}}>
                <div className={"container"}>

                    <Dropdown label={"Employee assigned"} icon={faUser} style={{marginRight: '55%'}}
                              value={this.state.employee} data={this.state.employeesList}
                              onChange={(employee)=>{this.setState({employee})}}/>

                    <Dropdown label={"Project assigned"} icon={faFolderOpen}
                              value={this.state.project} data={this.state.projectsList}
                              onChange={(project)=>{
                                  this.setState({project, devis: null});
                                  if(project !== null) this.loadProjectDevis(project.metadata)
                              }}/>

                    <Dropdown label={"Devis assigned"} icon={faFile} disabled={(this.state.devisProjectList === null || this.state.devisProjectList.metadata.length === 0)}
                              value={this.state.devis} data={this.state.devisProjectList}
                              onChange={(devis)=>{this.setState({devis})}}/>

                    <div className={"complement"} style={(this.state.project === null || this.state.project === "")? {visibility: "hidden", height: 0} : undefined}>
                        <p>
                            <span>Client : </span>
                            {(this.state.project !== null && this.state.project !== "")
                            && this.state.clientsList.find((client) => client['@id'] === this.state.project.metadata.client).name}
                        </p>
                        <p className={"team"}>
                            <span>Team : </span>
                            {(this.state.devis !== null && this.state.devis !== "" )
                            && this.state.teamsList.metadata.find((team) => team['@id'] === this.state.devis.metadata.team).name}
                        </p>
                    </div>

                    <InputDate label={"Start date"} icon={faHourglassStart}
                               value={this.state.startDate}
                               minDate={(this.state.loggedUserData.roles.includes("ROLE_ADMIN"))? null : new Date()}
                               onChange={(date)=>{this.setState({startDate: date})}}/>

                    <InputDate label={"End date"} icon={faHourglassEnd}
                               value={this.state.endDate}
                               minDate={this.state.startDate}
                               onChange={(date)=>{this.setState({endDate: date})}}/>

                    <InputTime label={"Time per day"} icon={faHourglassHalf}
                               value={this.state.timePerDay}
                               onChange={(time)=>{this.setState({timePerDay: time})}}/>

                    <InputType type={"text"} label={"Notes"} icon={faCommentDots}
                               value={this.state.content}
                               onChange={(e)=>{this.setState({content: e.target.value})}}/>

                    <Checkbox label={"Forecast prévisionnel"} value={this.state.previsionnel}
                              onClick={()=>{this.setState({previsionnel: !this.state.previsionnel})}}/>

                    <Checkbox label={"Forecast récurrente"} value={this.state.repeated}
                              onClick={()=>{this.setState({repeated: !this.state.repeated})}}/>
                </div>

                {
                    this.state.repeated ?
                        <>
                            <fieldset className={"days-container"}>
                                <legend><h2>Choose your days</h2></legend>
                                <div className={"days-options"}>
                                    <Checkbox label={"Monday"} value={this.state.days[0]}
                                              onClick={() => this.selectDays(0)}/>

                                    <Checkbox label={"Tuesday"} value={this.state.days[1]}
                                              onClick={() => this.selectDays(1)}/>

                                    <Checkbox label={"Wednesday"} value={this.state.days[2]}
                                              onClick={() => this.selectDays(2)}/>

                                    <Checkbox label={"Thursday"} value={this.state.days[3]}
                                              onClick={() => this.selectDays(3)}/>

                                    <Checkbox label={"Friday"} value={this.state.days[4]}
                                              onClick={() => this.selectDays(4)}/>
                                </div>
                            </fieldset>
                            <div className={"note"}>Note: This is available only for a 3 months period</div>
                        </>
                    : null
                }

                <div className={"control-container"}>
                    <button id={"add-trigger"} className={"important shake-constant"} onClick={() => this.addForecast()}>ADD</button>
                </div>
            </div>
        );
    }
}
