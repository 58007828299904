import {url} from "../conf/env";
import axios from "axios";
import Cookies from "js-cookie";
import {Base64} from "js-base64"



export let checkAuthentication = new Promise((resolveAuth) => {

    let query_params = window.location.search;

    if(window.location.search.startsWith("?auth="))
        checkAuthData(resolveAuth, query_params.split("=")[1]);

    else authenticate(resolveAuth);
});


export function logout(){
    window.location = url.sso + "/logout";
}


function authenticate(resolveAuth) {

    Cookies.remove("sso-forecast-token");
    axios.get(url.sso + "/isalive")
        .then(() => window.location = url.sso + "/authenticate?origin=" + window.location.href.split('?')[0])
        .catch(() => resolveAuth(null));
}


export function checkAuthData(resolveAuth, data){

    try{
        let split_token = Base64.decode(data).split("::");
        if(split_token.length !== 3) resolveAuth(null);

        let auth_data = {};
        auth_data.firstName = Base64.decode(split_token[0]);
        auth_data.lastName = Base64.decode(split_token[1]);


        new Promise((resolveToken => checkToken(split_token[2], resolveToken)))
            .then((token) => {
                if(token !== null){
                    auth_data.token = token;
                    resolveAuth(auth_data);
                }
                else resolveAuth(null);
            });
    }
    catch (e) {
        //Le traitement du token a échoué, on relance donc une authentification vers le SSO.
        authenticate(resolveAuth);
    }
}


export function checkToken(token, resolve) {

    axios.post(url.sso + "/authenticate",
        {token: token}, {headers: {"Content-Type": "application/x-www-form-urlencoded"}})

        .then((response) => {
            switch (response.status) {
                case 200:
                    //Le token est valide.
                    Cookies.set("sso-forecast-token", token);
                    Cookies.set("sso-forecast-data", response.data);
                    resolve(token);
                    break;

                case 202:
                    //Un code 202 est renvoyé lorsque les informations du token sont correctes mais que sa validité a expirée.
                    authenticate(resolve);
                    break;

                default:
                    resolve(null);
            }
        })
        .catch((error) => {
            console.error(error);
            //Si la requête AJAX échoue, le SSO est probablement indisponible. On retourne donc la page d'erreur d'authentification avec le 'resolve(null)'.
            resolve(null);
        });
}