import {momentFormat, responsiveDateFormatScales as scale} from "../conf/moment";

export function generateDateLabel(unit, date, labelWidth) {

    let label = null;

    switch (true) {
        case (labelWidth >= scale[3][0]):
            label = date.format(momentFormat[unit][scale[3][1]]); break;
        case (labelWidth >= scale[2][0]):
            label = date.format(momentFormat[unit][scale[2][1]]); break;
        case (labelWidth >= scale[1][0]):
            label = date.format(momentFormat[unit][scale[1][1]]); break;
        default:
            label = date.format(momentFormat[unit][scale[0][1]]);
    }

    if(unit === "week" && labelWidth >= 100) return "Week n° " + label;
    else if(["day", "hour"].includes(unit) && labelWidth <= 20) return "|";
    else return label;
}


export function formatDate(date){
    return [date.getDate(), date.getMonth()+1, date.getFullYear()]
        .map(n => n < 10 ? `0${n}` : `${n}`).join('/');
}