import EventBus from 'eventbusjs';
import getApiRawData from "./api-data-provider";
import {
    generateProjectsTimelineData,
    generateTeamsTimelineData,
    generateUsersTimelineData
} from "../helpers/timeline-helper";
import {addGroupsItems} from "../helpers/timeline-helper";
import {isEmpty} from "../helpers/obj-helper";
import {initMercureConnection} from "./sse-data-listener";


const forecastData = {
    projects: {},
    teams: {},
    users: {},
    timesheet_projects: {},
    timesheet_teams: {},
    timesheet_users: {}
};


const rawApiData = {};


export default function startForecastDataLoading(){

    initMercureConnection();

    getApiRawData().then((values) => {

        values.forEach((value) => rawApiData[value[0]] = value[1]);
        EventBus.dispatch("raw-data-event");
        generateForecastData();
    });
}


export function getForecastRawData(data_type){

    if(typeof data_type === "undefined") return rawApiData;
    else if(isEmpty(rawApiData[data_type]) || rawApiData[data_type] === null || typeof rawApiData[data_type] === "undefined") return null;
    else return rawApiData[data_type];
}


export function updateForecastRawData(data){
    Object.assign(rawApiData, data);
    generateForecastData();
}


export function getForecastData(data_type){

    if(typeof data_type === "undefined") return forecastData;
    else if(isEmpty(forecastData[data_type]) || forecastData[data_type] === null || typeof forecastData[data_type] === "undefined") return null;
    else return forecastData[data_type];
}


export function generateForecastData(){

    //PAGE PROJECTS DATA GENERATION
    let projectsTimelineData = generateProjectsTimelineData(rawApiData, false);
    forecastData.projects.groups = projectsTimelineData.groups;
    forecastData.projects.items = addGroupsItems(projectsTimelineData.groups, projectsTimelineData.items);
    EventBus.dispatch("projects-data-event");

    //PAGE TEAMS DATA GENERATION
    let teamsTimelineData = generateTeamsTimelineData(rawApiData, false);
    forecastData.teams.groups = teamsTimelineData.groups;
    forecastData.teams.items = addGroupsItems(teamsTimelineData.groups, teamsTimelineData.items);
    EventBus.dispatch("teams-data-event");

    //PAGE USERS DATA GENERATION
    let usersTimelineData = generateUsersTimelineData(rawApiData, false);
    forecastData.users.groups = usersTimelineData.groups;
    forecastData.users.items = addGroupsItems(usersTimelineData.groups, usersTimelineData.items);
    EventBus.dispatch("users-data-event");

    //PAGE TIMESHEET PROJECTS DATA GENERATION
    let projectsTimesheetTimelineData = generateProjectsTimelineData(rawApiData, true);
    forecastData.timesheet_projects.groups = projectsTimesheetTimelineData.groups;
    forecastData.timesheet_projects.items = addGroupsItems(projectsTimesheetTimelineData.groups, projectsTimesheetTimelineData.items);
    EventBus.dispatch("projects-timesheet-data-event");

    //PAGE TIMESHEET TEAMS DATA GENERATION
    let teamsTimesheetTimelineData = generateTeamsTimelineData(rawApiData, true);
    forecastData.timesheet_teams.groups = teamsTimesheetTimelineData.groups;
    forecastData.timesheet_teams.items = addGroupsItems(teamsTimesheetTimelineData.groups, teamsTimesheetTimelineData.items);
    EventBus.dispatch("teams-timesheet-data-event");

    //PAGE TIMESHEET USERS DATA GENERATION
    let usersTimesheetTimelineData = generateUsersTimelineData(rawApiData, true);
    forecastData.timesheet_users.groups = usersTimesheetTimelineData.groups;
    forecastData.timesheet_users.items  = addGroupsItems(usersTimesheetTimelineData.groups, usersTimesheetTimelineData.items);
    EventBus.dispatch("users-timesheet-data-event");
}


