import * as moment from "moment-business-days";


moment.updateLocale('en', {
    workingWeekdays: [1, 2, 3, 4, 5],
    week: {
        dow: 1,
    },
});


export const responsiveDateFormatScales = [
    [0, "short"], [50, "medium"], [140, "mediumLong"], [190, "long"]
];

export const momentFormat = {
    year: {
        long: 'YYYY',
        mediumLong: 'YYYY',
        medium: 'YYYY',
        short: 'YY'
    },
    month: {
        long: 'MMMM YYYY',
        mediumLong: 'MMMM',
        medium: 'MMMM',
        short: 'MM/YY'
    },
    week: {
        long: 'w',
        mediumLong: 'w',
        medium: 'w',
        short: 'w'
    },
    day: {
        long: 'dddd, LL',
        mediumLong: 'dddd, Do',
        medium: 'dd D',
        short: 'D'
    },
    hour: {
        long: 'dddd, LL, HH:00',
        mediumLong: 'L, HH:00',
        medium: 'HH:00',
        short: 'HH'
    },
    minute: {
        long: 'HH:mm',
        mediumLong: 'HH:mm',
        medium: 'HH:mm',
        short: 'mm',
    }
};