import React from "react";
import './style.scss'
import {checkIsDay, minutesToClock} from "../../../helpers/format-helper";


export const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {

    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    let backgroundStyle = (item.previsionnel)? `repeating-linear-gradient(45deg, rgba(0,0,0,0) 0, rgba(0,0,0,0) 1px, ${item.color} 0, ${item.color} 50%)` : item.color
    if(itemContext.selected) backgroundStyle = 'red'


    const classGenerator = () => {
        let _classes = ""
        if(item.isGroupItem) _classes += "timeline-group"
        else _classes += "timeline-item"

        if(itemContext.selected) _classes += " selected"
        if(item.previsionnel) _classes += " previsionnel"
        if(checkIsDay(item.start_time, item.end_time)) _classes += " timeline-small"

        return _classes
    }


    return (
        <div{...getItemProps({className: classGenerator(), style: {background: backgroundStyle}})}>
            {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

            <div className={"item-content"}>
                {isNaN(item.content)? item.content : (checkIsDay(item.start_time, item.end_time)) ? minutesToClock(item.content, ':') :  minutesToClock(item.content, 'h') + ' /day'}
            </div>

            {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
    );
};