import { convertArrayToCSV } from "convert-array-to-csv"
import moment from "moment";


export async function generateCsv(objects, object_type, rawApiData) {
    const delimiter = ','
    const date = moment.utc().format("DD/MM/YYYY")
    const header = []
    const csvContent = []

    objects.forEach(object => {
        if (object_type === 'forecasts') {
            csvContent.push([object.id, object.project, object.manager, object.firstName, object.lastName,
                object.tags, object.devis, moment.utc(object.start_date.date).format("DD/MM/YYYY"), moment.utc(object.end_date.date).format("DD/MM/YYYY"), object.hours])
        }
        if (object_type === 'reports') {
            csvContent.push([object.id, moment.utc(object.creation_date.date).format("DD/MM/YYYY HH:mm:ss"), object.client, object.project, object.devis, object.team,
                object.manager, object.resource, object.email, object.tags, (object.minutesPerDay / 60).toFixed(2).replaceAll('.', ','), object.content.replace(/<img[^>]*>/g,""), object.ticketNo]);
        }
        if (object_type === 'holidays') {
            csvContent.push([ object.firstName, object.lastName, object.email, moment.utc(object.dateCreated.date).format("DD/MM/YYYY"), moment.utc(object.startDate.date).format("DD/MM/YYYY"), moment.utc(object.endDate.date).format("DD/MM/YYYY"),
                object.totalDays, moment.utc(object.deadlineDate.date).format("DD/MM/YYYY"), object.holidayType, object.status]);
        }
    });
    if (object_type === 'forecasts') {
        header.push(["Id", "Project", "CP", "First name", "Last name", "Skill", "No de Devis", "Start Date", "End Date", "Hours"]);
    }
    if (object_type === 'reports') {
        header.push(["Id", "Creation Date", "Client", "Project", "Devis", "Team", "Manager", "Ressource", "Mail", "Tags", "Hours", "Content", "Ticket No"]);
    }
    if (object_type === 'holidays') {
        header.push(["First name", "Last name", "Email", "Creation Date", "Start Date", "End Date", "No of Days", "Validation Deadline", "Holiday Type", "Status"]);
    }
    downloadCsv(convertArrayToCSV(csvContent, {header, separator: delimiter}), `export-${object_type}-${date}.csv`)
}


export function downloadCsv(csv, filename){
    const blob = new Blob(
        ['\uFEFF', csv],
        { type: 'text/csv;charset=utf-8' })

    if (navigator.msSaveBlob) // IE 10+
        navigator.msSaveBlob(blob, filename);

    else {
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
