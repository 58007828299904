import React from "react";
import "./style.scss";
import {Button, Modal} from "react-bootstrap";


export default class ReportModal extends React.Component {

    render() {

        let forecast = this.props.forecast;

        if(typeof forecast !== "undefined" && forecast !== null){

            return (
                <Modal size="lg" centered show={this.props.show} onHide={this.props.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{"[" + forecast.project.displayData + "]"}</Modal.Title>
                        <span>Hour per day: {forecast.hourPerDay}</span>
                    </Modal.Header>

                    {/* RISQUE DE FAILLE XSS A CORRIGER */}
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{__html: forecast.content}}/>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        else return null;
    }
}