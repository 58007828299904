import React from 'react';
import Projects from "../pages/Projects/Index";
import Teams from "../pages/Teams/Index";
import TimeProjects from "../pages/Timeproj/Index";
import TimeTeams from "../pages/Timeteam/Index";
import AddForecast from "../pages/AddForecast/Index";
import Users from "../pages/Users/Index";
import Exporting from "../pages/Exporting/Index";
import TimeUsers from "../pages/Timeusers";


export const routes = [
    {
        path: "/",
        exact: true,
        comp: () => <Projects/>
    },
    {
        path: "/teams",
        exact: true,
        comp: () => <Teams/>
    },
    {
        path: "/users",
        exact: true,
        comp: () => <Users/>
    },
    {
        path: "/timeproj",
        exact: true,
        comp: () => <TimeProjects/>
    },
    {
        path: "/timeteam",
        exact: true,
        comp: () => <TimeTeams/>
    },
    {
        path:"/timeusers",
        exact: true,
        comp: () => <TimeUsers/>
    },
    {
        path: "/add-forecast",
        exact: true,
        comp: () => <AddForecast/>
    },
    {
        path: "/exporting",
        exact: true,
        comp: () => <Exporting/>
    }
];


export const restricted_routes = [
    {
        path: "/",
        exact: true,
        comp: () => <Projects/>
    },
    {
        path: "/teams",
        exact: true,
        comp: () => <Teams/>
    },
    {
        path: "/users",
        exact: true,
        comp: () => <Users/>
    },
    {
        path: "/timeproj",
        exact: true,
        comp: () => <TimeProjects/>
    },
    {
        path: "/timeteam",
        exact: true,
        comp: () => <TimeTeams/>
    }
];