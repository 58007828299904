import React from "react";
import "./style.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeField from "react-simple-timefield";


export class InputType extends React.Component {

    render() {
            return (
                <div className={"input"} style={this.props.style}>
                    <FontAwesomeIcon className={"icon"} icon={this.props.icon}/>
                    <div className="group">
                        <input type={this.props.type} value={this.props.value} onChange={this.props.onChange} required/>
                        <span className="highlight"/>
                        <span className="bar"/>
                        <label>{this.props.label}</label>
                    </div>
                </div>
            );
    }
}


export class InputTime extends React.Component {

    render() {
        return (
            <div className={"input time"} style={this.props.style}>
                <FontAwesomeIcon className={"icon"} icon={this.props.icon}/>
                <div className="group">
                    <TimeField
                        className={"time-field"}
                        value={this.props.value}
                        onChange={(event, value) => this.props.onChange(value)}
                        input={
                            <input value={this.props.value} type={"text"} required/>
                        }
                        colon=":"
                    />
                    <span className="highlight"/>
                    <span className="bar"/>
                    <label>{this.props.label}</label>
                </div>
            </div>
        );
    }
}


export class InputDate extends React.Component {

    render() {

        const isWeekday = date => {
            const day = date.getDay();
            return day !== 0 && day !== 6;
        };

        const DisplayInput = React.forwardRef(({ value, onClick, label}, ref) => (
            <div ref={ref} className="group">
                <input type={"text"} value={value} onClick={onClick} required/>
                <span className="highlight"/>
                <span className="bar"/>
                <label>{label}</label>
            </div>
        ));

        return (
            <div className={"input date"} style={this.props.style}>
                <FontAwesomeIcon className={"icon"} icon={this.props.icon}/>
                <DatePicker
                    selected={this.props.value}
                    dateFormat="dd/MM/yyyy"
                    todayButton="Today"
                    filterDate={isWeekday}
                    showWeekNumbers
                    withPortal
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    onChange={this.props.onChange}
                    customInput={<DisplayInput ref={React.createRef()} label={this.props.label}/>}
                />
            </div>
        );
    }
}