import React from "react";
import "./style.scss";
import Overview from "./Overview";
import ForecastForm from "./ForecastForm";
import {animErrorPost} from "../../helpers/animation";
import moment from "moment-business-days";
import { Redirect } from "react-router-dom"
import {query_api_post} from "../../helpers/ajax";
import {clockToMinutes} from "../../helpers/format-helper";
import loader from "../../res/icons/loader-black.svg";


export default class AddForecast extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            classContainerDisplayed: "form",
            forecastList: [],
            keepFormState: true,
            redirect: false,
            isPosting: false,
            error: "",
            showError:false
        };
        this.toggleContainerSwitch = this.toggleContainerSwitch.bind(this);
    }


    toggleContainerSwitch(container_name){
        this.setState({classContainerDisplayed: container_name});
        if(container_name === "form") this.setState({keepFormState: true});

        this.moveMask(container_name);
    }


    moveMask(_dest) {
        let mask = document.getElementById("state-mask");
        let container = document.getElementById("state-container");

        if(_dest === "form")
            mask.style.left = "-50px";
        else if(_dest === "overview")
            mask.style.left = container.offsetWidth / 2 - mask.offsetWidth / 2 + "px";
        else if(_dest === "post")
            mask.style.left = container.offsetWidth - mask.offsetWidth + 50 + "px";
    }


    addForecast(forecast){
        let new_forecast_list = [...this.state.forecastList]

        if(Array.isArray(forecast)) {
            new_forecast_list = new_forecast_list.concat(forecast);
        }else {
            new_forecast_list.push(forecast);
        }

        this.setState({
            keepFormState: false,
            forecastList: new_forecast_list
        });
        this.toggleContainerSwitch("overview");
    }


    removeForecast(forecast_index){
        let forecastList = [...this.state.forecastList];
        forecastList.splice(forecast_index, 1);
        this.setState({forecastList});
    }


    postForecasts(){

        let post_promises = [];
        this.setState({isPosting: true})

        this.state.forecastList.forEach((new_forecast) =>{

            let duration = moment(new_forecast.endDate).endOf('day').businessDiff(moment(new_forecast.startDate).startOf('day'), 'days');

            post_promises.push(query_api_post("/api/forecasts", {
                minutesPerDay: clockToMinutes(new_forecast.timePerDay, ":"),
                startDate: moment(new_forecast.startDate).format('YYYY-MM-DD'),
                endDate: moment(new_forecast.endDate).format('YYYY-MM-DD'),
                devis: new_forecast.devis.metadata['@id'],
                employee: new_forecast.employee.metadata['@id'],
                content: new_forecast.content,
                creationDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                dayDuration: (duration === 0)? 1 : duration,
                previsionnel: new_forecast.previsionnel
            }));
        });

        Promise.all(post_promises)
            .then((response) => {
                this.setState({redirect: true, isPosting: false})
                console.log(response);
            })
            .catch((error) => {
                console.error(error);
                animErrorPost();
                this.setState({isPosting: false})
                if(error.response.data && error.response.data['hydra:description']) {
                    this.setState({
                        error: error.response.data['hydra:description'],
                        showError: true
                    });

                    setTimeout(function(){
                        this.setState({
                            error: '',
                            showError: false
                        });
                    }.bind(this),3000);
                };
            });
    }


    render() {

        let message = "Please fill in the form.";
        if(this.state.classContainerDisplayed === "overview") message = "You can add more forecast in the first section.";
        else if(this.state.classContainerDisplayed === "post") message = "Are you sure ?";

        if(this.state.redirect)
            return <Redirect to='/'/>;

        else
            return(
                <div className={"page"}>
                    <div id={"add-forecast-container"}>

                        <h2>ADD FORECAST</h2>
                        <p id={"advice"}>{message}</p>

                        <div id={"state-band"}>
                            <div id={"state-container"}>
                                <span id="state-mask">{this.state.classContainerDisplayed.toUpperCase()}</span>

                                <span className={(this.state.classContainerDisplayed === "form")? "active" : null}
                                      onClick={()=>this.toggleContainerSwitch("form")}>FORM</span>

                                <span className={(this.state.classContainerDisplayed === "overview")? "active" : null}
                                      onClick={()=>this.toggleContainerSwitch("overview")}>OVERVIEW</span>

                                <span className={(this.state.classContainerDisplayed === "post")? "active" : null}
                                      onClick={()=>this.toggleContainerSwitch("post")}>POST</span>
                            </div>
                        </div>

                        {this.state.keepFormState &&
                            <ForecastForm display={(this.state.classContainerDisplayed === "form")}
                                          addForecast={(forecast) => this.addForecast(forecast)}/>
                        }

                        {this.state.classContainerDisplayed === "overview" &&
                            <Overview forecastList={this.state.forecastList} removeForecast={(index) => this.removeForecast(index)}
                                      switchContainer={(container) => this.toggleContainerSwitch(container)}/>
                        }

                        {this.state.classContainerDisplayed === "post" &&
                                <div className={"post content"}>
                                    <div className={"container"}>
                                        <p>You are about to add {this.state.forecastList.length} forecasts. Are you sure ?</p>
                                    </div>

                                    {(!this.state.isPosting)?
                                        <div className={"control-container"}>
                                            <button onClick={()=>{this.postForecasts()}}>YES</button>
                                            <button className={"important"} onClick={()=>this.toggleContainerSwitch("overview")}>CANCEL</button>
                                        </div>
                                        :
                                        <img src={loader} alt={"loader"}/>
                                    }
                                </div>
                        }
                    </div>
                    {this.state.showError ? <div id="snackbar">{this.state.error}</div> : null}
                </div>
            )
    }
}