import React from "react";
import {Button, Modal} from "react-bootstrap";
import {InputDate, InputTime} from "../Form/Input/Index";
import loader from "../../res/icons/loader-black.svg";
import {faHourglassEnd, faHourglassHalf, faHourglassStart} from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

export default class DuplicateForecastModal extends React.Component{
    render() {
        return (
            <Modal size={"md"} centered={true} show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header>
                    <Modal.Title>{"Duplicate Forecast"}</Modal.Title>
                </Modal.Header>

                <Modal.Body className={"page"}>
                    <div className={"duplicate-container"}>
                        <InputDate
                            label={"Start date"}
                            icon={faHourglassStart}
                            value={this.props.startDate}
                            onChange={(date) => this.props.startDateChange(date)}
                        />

                        <InputDate
                            label={"End date"}
                            icon={faHourglassEnd}
                            value={this.props.endDate}
                            minDate={this.props.startDate}
                            onChange={(date) => this.props.endDateChange(date)}
                        />

                        <InputTime
                            label={"Time per Day"}
                            icon={faHourglassHalf}
                            value={this.props.timePerDay}
                            onChange={(time) => this.props.timePerDayChange(time)}
                        />
                    
                        <p id={"advice"}>{this.props.errorMessage}</p>
                    </div>
                </Modal.Body>

                <Modal.Footer className="footer">
                   {this.props.loading ? 
                        <img src={loader} alt={"loader"}/> :
                        <>
                            <Button onClick={this.props.addForecast} id={"add-trigger"}>
                                Ok
                            </Button>

                            <Button className={"important"} onClick={this.props.handleClose}>
                                Cancel
                            </Button>
                        </> 
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}