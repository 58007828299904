import Table from "react-bootstrap/Table";
import {formatDate} from "../../helpers/date-helper";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import AddForecastModal from "../../components/Modal/AddForecastModal";
import ReactTooltip from "react-tooltip";


export default class Overview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalContent: null
        }
    }


    handleCloseModal(){
        this.setState({showModal: false});
    }


    displayModal(forecast){
        this.setState({
            showModal: true,
            modalContent: forecast
        });
    }


    render() {
        return (
            <div className={"overview content"}>
                <div className={"container"}>
                    <Table className={"overview-table"} borderless striped responsive>
                        <thead>
                        <tr>
                            <th>PROJECT</th>
                            <th>START DATE</th>
                            <th>END DATE</th>
                            <th>EMPLOYEE</th>
                            <th>TIME</th>
                            <th>NOTE</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.forecastList.map((forecast, index) =>
                                <tr key={index}>

                                    <td className={"project"} data-tip={"Devis: " + forecast.devis.displayData} data-for={"display-devis-data"}>{forecast.project.displayData}</td>
                                    <td>{formatDate(forecast.startDate)}</td>
                                    <td>{formatDate(forecast.endDate)}</td>
                                    <td>{forecast.employee.displayData}</td>
                                    <td>{forecast.timePerDay.replace(':', 'h') + " /day"}</td>
                                    <td className={"forecast-content"} onClick={() => this.displayModal(forecast)}>{forecast.content.substring(0, 4) + " ..."}</td>

                                    <td className={"delete-forecast"}
                                        onClick={() => this.props.removeForecast(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>

                    <ReactTooltip id={"display-devis-data"} type={"dark"} place="bottom" effect="solid"/>

                    <AddForecastModal show={this.state.showModal} forecast={this.state.modalContent} handleClose={() => this.handleCloseModal()}/>

                </div>

                <div className={"control-container"}>
                    <button onClick={()=>this.props.switchContainer("form")}>ADD MORE</button>
                    <button className={"important"} onClick={()=>this.props.switchContainer("post")}>POST</button>
                </div>
            </div>
        );
    }
}