import React from "react";
import "./style.scss";
import logo from "../../res/images/esokia_logo.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import {url} from "../../conf/env";


export default class AuthenticationFail extends React.Component {

    render() {
        return(
            <div id={"authentication-fail"}>
                <img src={logo} alt={"Esokia Logo"}/>
                <h1>FORECAST</h1>
                <div id={"message"}>
                    AUTHENTICATION FAILED!
                </div>
                <div id={"redirection"}>
                    <a href={url.forecast}><FontAwesomeIcon className={"icon"} icon={faRedo}/></a>
                    <a href={url.sso} className={"icon"}>SSO</a>
                </div>
            </div>
        )
    }
}