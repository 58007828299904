import React from "react";
import Calendar from "../../components/Timeline/Calendar";
import Loading from "../../components/Loading/Index";
import EventBus from "eventbusjs";
import {getForecastData} from "../../providers/provider";


export default class TimeTeams extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: null
        };
        this.getPageData = this.getPageData.bind(this);
    }


    componentDidMount(): void {
        this.getPageData();
        EventBus.addEventListener('teams-timesheet-data-event', this.getPageData);
    }


    componentWillUnmount(): void {
        EventBus.removeEventListener('teams-timesheet-data-event', this.getPageData);
    }


    getPageData = function(){
        this.setState({data: getForecastData("timesheet_teams")});
    };


    render() {

        if(this.state.data !== null)
            return <Calendar groups={this.state.data.groups} items={this.state.data.items} type={"team"} isTimesheet={true} canEditItems={false}/>;

        else return (
            <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: "center"}}>
                <Calendar groups={[]} items={[]} type={"teams"} isTimesheet={true} canEditItems={false}/>
                <Loading message={"Loading timesheet teams"}/>
            </div>
        )
    }
}