import React from "react";
import Header from "./components/Navigation/Navbar";
import {routes, restricted_routes} from "./conf/routes";
import {Router, Route} from "react-router-dom";
import {checkToken} from "./helpers/security";
import Cookies from "js-cookie";
import AuthenticationFail from "./pages/Authentication/fail";
import {Base64} from "js-base64"


export default class App extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            authData: this.props.authData,
            authTokenIsCorrect: true,
            routerHistory: require("history").createBrowserHistory(),
            loggedUserData: JSON.parse(Base64.decode(Cookies.get("sso-forecast-data")))
        }
    }

    componentDidMount(){

        this.state.routerHistory.listen((location, action) =>
            new Promise((resolveToken) => checkToken(Cookies.get("sso-forecast-token"), resolveToken))
                .then((token) =>
                    this.setState({authTokenIsCorrect: token !== null}))
            )
    }


    render() {

        if(this.state.authTokenIsCorrect){

            let enabled_routes = (this.state.loggedUserData.roles.includes("ROLE_LEADER")) ? restricted_routes : routes;

            return(
                <Router history={this.state.routerHistory}>

                    <Header userFirstName={this.state.authData.firstName} userLastName={this.state.authData.lastName}/>

                    <div id={"app_content"}>
                        {enabled_routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.comp}
                            />
                        ))}
                    </div>
                </Router>
            );
        }

        else
            return (
                <div id={"app_content"}>
                    <AuthenticationFail/>
                </div>
            )
    }
}