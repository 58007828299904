import React from "react";
import Calendar from "../../components/Timeline/Calendar";
import Loading from "../../components/Loading/Index";
import {getForecastData} from "../../providers/provider";
import EventBus from 'eventbusjs';


export default class Projects extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: null
        };
        this.getPageData = this.getPageData.bind(this);
    }


    componentDidMount(): void {
        this.getPageData();
        EventBus.addEventListener('projects-data-event', this.getPageData);
    }


    componentWillUnmount(): void {
        EventBus.removeEventListener('projects-data-event', this.getPageData);
    }


    getPageData = function(){
        this.setState({data : getForecastData("projects")});
    };


    render() {
        if(this.state.data !== null)
            return <Calendar groups={this.state.data.groups} items={this.state.data.items} type={"projects"} isTimesheet={false} canEditItems={true}/>;

        else return (
            <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: "center"}}>
                <Calendar groups={[]} items={[]} type={"projects"}  isTimesheet={false} canEditItems={true}/>
                <Loading message={"Loading projects"}/>
            </div>
        )
    }
}