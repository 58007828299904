import React, {useEffect, useState} from "react"
import classes from "./style.module.scss"
import {InputDate} from "../../components/Form/Input/Index";
import {faHourglassEnd, faHourglassStart} from "@fortawesome/free-solid-svg-icons";
import {getForecastRawData} from "../../providers/provider";
import loader from "../../res/icons/loader.svg";
import {generateCsv} from "../../helpers/csv";
import EventBus from "eventbusjs";
import moment from "moment";
import {query_api_export} from "../../helpers/ajax";


export default function Exporting() {

    const [enabled, setEnabled] = useState(false)
    const [startDate, setStartDate] = useState("")
    const [current_menu, setCurrentMenu] = useState("reports")
    const [endDate, setEndDate] = useState("")

    const today = new Date();

    useEffect(() => {
        if (getForecastRawData().reports !== undefined) setEnabled(true)
        else {
            EventBus.addEventListener('raw-data-event', () => setEnabled(true));
            return () => EventBus.removeEventListener('raw-data-event', () => setEnabled(true));
        }
    }, [])


    const handleReportsClick = (objectType) => {
        setEnabled(false);
        const newEndDate = moment(endDate);
        const newStartDate = moment(startDate);
        newStartDate.set({h:0, m:0, s:0}).utc(true);
        newEndDate.set({h:23, m:59, s:59}).utc(true);

        query_api_export('/api/export-' + objectType, newStartDate.format("YYYY-MM-DD HH:mm:ss"), newEndDate.format("YYYY-MM-DD HH:mm:ss")).then(response => {
            generateCsv(response, objectType);
        });

        setEnabled(true);
    }

    function  showMenu (menu) {
        setCurrentMenu(menu)
        setStartDate('');
        setEndDate('');
    }

    return(
        <div className={"page"}>
            <div className={classes.container}>
                <h2>EXPORTING</h2>
                <p id={"advice"}>Export your related entities</p>

                <div className={classes.stateBand}>
                    <div className={classes.stateContainer}>
                        <span onClick={showMenu.bind(this,'reports')} className={current_menu==='reports' ? classes.stateMask : ''}>REPORTS</span>
                        <span></span>
                        <span onClick={showMenu.bind(this,'forecasts')} className={current_menu==='forecasts' ? classes.stateMaskActive : ''}>FORECASTS</span>
                        <span onClick={showMenu.bind(this,'holidays')} className={current_menu==='holidays' ? classes.lastSpanActive : ''}>HOLIDAYS</span>
                    </div>
                </div>

                <div className={classes.content}>
                    <div className={classes.form}>
                        <InputDate label={"Start date"} icon={faHourglassStart}
                                   value={startDate}
                                   onChange={(date) => setStartDate(date)}/>

                        <InputDate label={"End date"} icon={faHourglassEnd}
                                   value={endDate}
                                   minDate={startDate}
                                   maxDate={current_menu === 'reports' ? today : ''}
                                   onChange={(date) => setEndDate(date)}/>
                    </div>

                    <div className={classes.control}>
                        <button onClick={handleReportsClick.bind(this,current_menu)} className={classes.important} disabled={!enabled}>
                            {(enabled)?
                                "GENERATE CSV"
                                :
                                <img src={loader} alt={"loader"} style={{marginLeft: 5}}/>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
